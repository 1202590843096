@keyframes scale1 {
	0% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
	50% {
		transform: scale(0.9);
		-webkit-transform: scale(0.9);
	}
	100% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

@keyframes scale2 {
	0% {
		transform: scale(0.95);
		-webkit-transform: scale(0.95);
	}
	50% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
	100% {
		transform: scale(0.95);
		-webkit-transform: scale(0.95);
	}
}

.scaleSize1 {
	transform: scale(1);
	animation: scale1 6s ease-in-out infinite;
	-webkit-animation: scale1 6s ease-in-out infinite;
}

.scaleSize2 {
	transform: scale(1);
	animation: scale2 6s ease-in-out infinite;
	-webkit-animation: scale2 6s ease-in-out infinite;
}

.reveal {
	animation: fadeIn 1s;
}
@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: scale(0.95);
		-webkit-transform: scale(0.95);
	}
	100% {
		opacity: 1;
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

.fadeOut {
	animation: fadeOut 0.5s;
	opacity: 0;
	transform: scale(0.9);
	-webkit-transform: scale(0.9);
}
@keyframes fadeOut {
	0% {
		opacity: 1;
		transform: scale(1);
		-webkit-transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(0.9);
		-webkit-transform: scale(0.9);
	}
}

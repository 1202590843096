.shadowEffect {
	box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.05);

	-webkit-transition: all 0.5s ease-out 0s;
	-moz-transition: all 0.5s ease-out 0s;
	-ms-transition: all 0.5s ease-out 0s;
	-o-transition: all 0.5s ease-out 0s;
	transition: all 0.5s ease-out 0s;
}

.hoverEffect:hover {
	box-shadow: 0 15px 35px 0 rgba(9, 11, 17, 0.1);
	// position: relative;
	// top: -50px;
	cursor: pointer;

	// -webkit-transition: all 0.5s ease-out 0s;
	// -moz-transition: all 0.5s ease-out 0s;
	// -ms-transition: all 0.5s ease-out 0s;
	// -o-transition: all 0.5s ease-out 0s;
	// transition: all 0.5s ease-out 0s;
}
